<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-autocomplete
                    v-model="selectedVehicles"
                    label="Select Vehicles"
                    chips
                    multiple
                    :items="vehicles"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                  offset-md="2"
                  offset-lg="2"
                  offset-xl="2"
                >
                  <v-select
                    v-model="reportType"
                    :items="reportItems"
                    label="Select Report"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                  <div class="float-right">
                    <fab-button v-if="this.selectedVehicles.length <= 5"
                      @click="getData()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-play'"
                      :tooltipSpan="'Generate Report'"
                    ></fab-button
                    >&nbsp;
                    <fab-button
                      @click="downloadExcel()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-file-excel'"
                      :tooltipSpan="'Download Report'"
                    ></fab-button
                    >&nbsp;
                    <fab-button
                      @click="getData()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-refresh'"
                      :tooltipSpan="'Refresh'"
                    ></fab-button>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="pr-3">
                  <v-menu
                    v-model="menuDateFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFrom"
                        label="Date & Time From"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateFrom"
                      :max="$dateFormat(new Date(), 'YYYY-MM-DD')"
                      @input="menuDateFrom = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-3">
                  <input
                    v-model="timeFrom"
                    type="time"
                    step="1"
                    style="width: 250px"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="pr-2">
                  <v-menu
                    v-model="menuDateTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateTo"
                        label="Date & Time To"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <!-- <v-date-picker
                      v-model="dateTo"
                      :max="dateTo"
                      :min="dateFrom"
                      @input="menuDateTo = false"
                    ></v-date-picker> -->
                    <v-date-picker
                      v-model="dateTo"
                      :max="dateToLimit"
                      :min="dateFrom"
                      @input="menuDateTo = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="pl-2">
                  <input
                    v-model="timeTo"
                    type="time"
                    step="1"
                    style="width: 250px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody v-for="(item, index) in items" :key="index">
              <tr v-for="(obj, i) in item.temperature" :key="i">
                <td
                  class="text-no-wrap text-center"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ index + 1 }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.plate_no }}
                </td>
                <td
                  :style="i === 1 ? 'border-bottom: none;' : 'display: none;'"
                  colspan="2"
                  :rowspan="item.temperature.length"
                ></td>
                <td class="text-center text-no-wrap">
                  {{ $dateFormat(obj.datetimestamp, "YYYY-MM-DD") }}
                </td>
                <td class="text-center text-no-wrap">
                  {{ $dateFormat(obj.datetimestamp, "HH:mm:ss") }}
                </td>
                <td class="text-right text-no-wrap">
                  {{ obj.temperature + " °C" }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubCompanyServices from "@/services/SubCompanyServices";
import Services from "@/services/CompanyServices";
import XLSX from "xlsx";

export default {
  data: () => {
    return {
      title: "Temperature Report",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Plate No", sortable: false },
        { text: "Date", sortable: false, align: "center" },
        { text: "Time", sortable: false, align: "center" },
        { text: "Temperature", sortable: false, align: "right" },
      ],
      items: [],
      data: {},
      queryParams: {
        search: "",
      },
      vehicleQueryParams: {
        search: "",
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      dateFrom: new Date().toISOString().substr(0, 10),
      timeFrom: "00:00:00",
      menuDateFrom: false,
      dateTo: new Date().toISOString().substr(0, 10),
      timeTo: "23:59:59",
      menuDateTo: false,
      dateToLimit: new Date().toISOString().substr(0, 10),
      selectedVehicles: [],
      vehicles: [],
      fullWidthDialogLoading: false,
      reportItems: [
        {
          text: "Tracklist Report",
          value: "/c/s/tracklist-report",
        },
        {
          text: "KM Run Report",
          value: "/c/s/km-run-report",
        },
        {
          text: "Working Hours Report",
          value: "/c/s/working-hours-report",
        },
        {
          text: "Service Meter Report",
          value: "/c/s/smr-report",
        },
        {
          text: "Temperature Report",
          value: "/c/s/temperature-report",
        },
        {
          text: "Fuel Sensor Report",
          value: "/c/s/fuel-sensor-report",
        },
      ],
    };
  },
  created: async function() {
    if (this.$store.state.token !== null) {
      this.$store.dispatch("startLoading");

      this.$store.dispatch("dispatchReportTypeText", "Temperature");

      this.selectedVehicles = this.$store.state.reportVehiclesArray;

      const vehiclesItems = await this.setVehicles();

      const returnedVehicles = await Promise.all(
        vehiclesItems.map((item) => {
          if (item.gps_device) {
            return {
              text: item.plate_no.toUpperCase(),
              value: item.id,
            };
          }
        })
      );

      this.vehicles = returnedVehicles.filter((item) => {
        return item;
      });

      this.$store.dispatch("endLoading");
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    reportType: {
      get() {
        // return this.$store.state.reportTypeValue;
        return "/c/s/temperature-report";
      },
      set(val) {
        this.items.length = 0;
        this.$store.dispatch("dispatchReportTypeValue", val);
        this.$router.push({
          path: val,
        });
      },
    },
  },
  watch: {
    selectedVehicles: function() {
      this.$store.dispatch(
        "dispatchReportVehiclesArray",
        this.selectedVehicles
      );
    },
    dateFrom: function() {
      this.dateTo = this.dateFrom;
      const dateTo = this.$addSevenCurrentDate(this.dateFrom);
      // this.dateTo = this.$dateValidator(dateTo);
      this.dateToLimit = this.$dateValidator(dateTo);
    },
  },
  methods: {
    search(value) {
      this.queryParams.search = value;
      this.setEmployees(this.queryParams);
    },
    async getData() {
      this.$store.dispatch("startLoading");

      const body = {
        vehicles: this.selectedVehicles,
        date_time_from: this.$dateFormat(
          this.dateFrom + " " + this.timeFrom,
          "YYYY-MM-DD HH:mm:ss"
        ),
        date_time_to: this.$dateFormat(
          this.dateTo + " " + this.timeTo,
          "YYYY-MM-DD HH:mm:ss"
        ),
      };

      try {
        let response = (await Services.getTemperatureReport(body)).data;

        this.items = response.data;

        this.$store.dispatch("dispatchHasItems", true);
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    async setVehicles() {
      let data;

      try {
        this.vehicleQueryParams.sub_company_id = this.$store.state.subcompany.id;

        const response = await SubCompanyServices.getVehicles(
          this.vehicleQueryParams
        );
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
      return data;
    },
    async downloadExcel() {
      let dataToDownload = [];
      let header = [];

      for (let i = 0; i < this.headers.length; i++) {
        header.push(this.headers[i].text);
      }

      header.splice(0, 1);

      dataToDownload.push(header);

      for (let i = 0; i < this.items.length; i++) {
        for (let e = 0; e < this.items[i].temperature.length; e++) {
          let theItem = [];

          if (e === 0) {
            theItem.push(this.items[i].plate_no);
            theItem.push(
              this.$dateFormat(
                this.items[i].temperature[e].datetimestamp,
                "YYYY-MM-DD"
              )
            );
            theItem.push(
              this.$dateFormat(
                this.items[i].temperature[e].datetimestamp,
                "HH:mm:ss"
              )
            );
            theItem.push(this.items[i].temperature[e].temperature + " °C");
          } else {
            theItem.push("");
            theItem.push(
              this.$dateFormat(
                this.items[i].temperature[e].datetimestamp,
                "YYYY-MM-DD"
              )
            );
            theItem.push(
              this.$dateFormat(
                this.items[i].temperature[e].datetimestamp,
                "HH:mm:ss"
              )
            );
            theItem.push(this.items[i].temperature[e].temperature + " °C");
          }

          dataToDownload.push(theItem);
        }
      }

      // don't delete any codes after this comment got it?!

      let wscols = [
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 },
        { wch: 25 },
      ];

      let wsrows = [];

      let ws = XLSX.utils.aoa_to_sheet(dataToDownload, { cellDates: true });
      let wb = XLSX.utils.book_new();

      let fmt = "#,##0";

      let range = XLSX.utils.decode_range(ws["!ref"]);
      for (let e = range.s.r + 1; e <= range.e.r; ++e) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let ref = XLSX.utils.encode_cell({ r: e, c: C });
          if (!ws[ref]) continue;
          if (ws[ref].t !== "n") continue;
          ws[ref].z = fmt;
        }
      }

      XLSX.utils.book_append_sheet(wb, ws);

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;

      wb.Props = {
        Title: this.title,
        Subject: this.title,
        Author: "Developer of Journeytech",
        Company: "Journeytech",
        Category: "Reports",
      };

      // ws["!protect"] = {
      //   password: "Journeytech@1",
      //   formatRows: false,
      //   formatColumns: false,
      //   objects: true,
      //   scenarios: true
      // };

      if (!wb.Workbook) wb.Workbook = { Sheets: [], WBProps: {} };
      if (!wb.Workbook.WBProps) wb.Workbook.WBProps = {};
      wb.Workbook.WBProps.filterPrivacy = true;

      let filenames = [
        [this.title + ".xlsx"],
        [this.title + ".xlsm"],
        [this.title + ".xlsb"],
        [this.title + ".xlam"],
        [this.title + ".biff8.xls", { bookType: "xls" }],
        [this.title + ".biff5.xls", { bookType: "biff5" }],
        [this.title + ".biff2.xls", { bookType: "biff2" }],
        [this.title + ".xml.xls", { bookType: "xlml" }],
        [this.title + ".xla"],
        [this.title + ".ods"],
        [this.title + ".fods"],
        [this.title + ".csv"],
        [this.title + ".txt"],
        [this.title + ".slk"],
        [this.title + ".eth"],
        [this.title + ".htm"],
        [this.title + ".dif"],
        [this.title + ".dbf", { sheet: "Hidden" }],
        [this.title + ".rtf"],
        [this.title + ".prn"],
      ];
      let OUT = ["base64", "binary", "string", "array"];
      if (typeof Buffer !== "undefined") OUT.push("buffer");
      filenames.forEach(function(r) {
        XLSX.writeFile(wb, r[0], r[1]);
        if (typeof process !== "undefined") XLSX.readFile(r[0]);

        let ext = (r[1] && r[1].bookType) || r[0].split(".")[1];
        ext = { htm: "html" }[ext] || ext;
        OUT.forEach(function(type) {
          if (
            type === "string" &&
            [
              "xlsx",
              "xlsm",
              "xlsb",
              "xlam",
              "biff8",
              "biff5",
              "biff2",
              "xla",
              "ods",
              "dbf",
            ].indexOf(ext) > -1
          )
            return;
          if (
            type === "array" &&
            ["xlsx", "xlsm", "xlsb", "xlam", "ods"].indexOf(ext) > -1 &&
            typeof Uint8Array === "undefined"
          )
            return;
          let datout = XLSX.write(wb, {
            type: type,
            bookType: ext,
            sheet: (r[1] && r[1].sheet) || null,
          });
          XLSX.read(datout, { type: type });
          if (type === "array") console.log(ext, datout);
        });
      });
    },
    cutHeadings(data) {
      let text = null;
      text = data.length < 5 ? data : data.slice(0, 40) + "...";
      return text;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
tbody tr:hover {
  background-color: transparent !important;
}

input[type="time"] {
  /* border: 5px solid #b71c1c; */
  color: #000000;
  font-size: 14px;
  font-family: helvetica;
  width: 100%;
}

input[type="time"]:focus {
  outline: none;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
input[type="time"]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* Minute */
input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* Second */
input[type="time"]::-webkit-datetime-edit-second-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  background-color: #b71c1c;
  border-radius: 15%;
  color: #fff;
  padding: 19px 13px;
}

/* 'X' button for resetting/clearing time */
input[type="time"]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}

.scrolling-wrapper-two {
  overflow-x: hidden;
  height: 63vh;
  overflow-y: scroll;
  white-space: nowrap;
}

@media (max-width: 360px) and (max-height: 640px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 411px) and (max-height: 731px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 411px) and (max-height: 823px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 414px) and (max-height: 736px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 375px) and (max-height: 812px) {
  .heightMap {
    height: 250px;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .heightMap {
    height: 250px;
  }
}
</style>
